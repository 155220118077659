import axios from 'axios';

export const callExternalApi = async (options, accessToken) => {
  // eslint-disable-next-line
  console.log(`config: ${JSON.stringify(options)}`);
  try {
    const config = {
      url: options.url,
      method: options.config.method,
      headers: {
        ...options.config.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    };
    if (options.config.data) {
      config.data = options.config.data;
    }
    // eslint-disable-next-line
    console.log(`config: ${JSON.stringify(config)}`);
    const response = await axios(config);
    // eslint-disable-next-line
    console.log(`response: ${JSON.stringify(response)}`);
    const { data } = response;
    // eslint-disable-next-line
    console.log(`data: ${JSON.stringify(data)}`);
    return {
      data,
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error;

      const { response } = axiosError;

      let message = 'http request failed';

      if (response && response.statusText) {
        message = response.statusText;
      }

      if (axiosError.message) {
        message = axiosError.message;
      }

      if (response && response.data && response.data.message) {
        message = response.data.message;
      }

      return {
        data: null,
        error: {
          message,
        },
      };
    }

    return {
      data: null,
      error: {
        message: error.message,
      },
    };
  }
};

export default callExternalApi;
