class GecoRouter {
  static projectsList() {
    return '/';
  }

  static gecoApi() {
    return '/doc/v1/';
  }

  static docsTab(projectId = ':projectId', versionId = ':versionId', time = ':time') {
    return `/docs/${projectId}/${versionId}/${time}`;
  }

  static runsTab(projectId = ':projectId', versionId = ':versionId', time = ':time') {
    return `/models/${projectId}/${versionId}/${time}`;
  }

  static projectVersionDoc(projectId = ':projectId', versionId = ':versionId', fileName = ':fileName') {
    return `/document/${projectId}/${versionId}/${fileName}`;
  }

  static projectVersionFile(projectId = ':projectId', versionId = ':versionId', fileName = ':fileName') {
    return `/file/${projectId}/${versionId}/${fileName}`;
  }

  static projectVersionPdf(projectId = ':projectId', versionId = ':versionId', fileName = ':fileName') {
    return `/pdf/${projectId}/${versionId}/${fileName}`;
  }

  static crm() {
    return '/crm';
  }

  static crmWebAppActivity() {
    return '/crm/activity/webapp';
  }

  static crmApiActivity() {
    return '/crm/activity/api';
  }

  static crmActivityByDays() {
    return '/crm/activity/days';
  }

  static crmCompany() {
    return '/crm/company';
  }

  static crmProject() {
    return '/crm/project';
  }

  static crmModelRun() {
    return '/crm/modelrun';
  }

  static crmUser() {
    return '/crm/user';
  }

  static projectVersions(shortName = ':shortName') {
    return `/project/versions/${shortName}`;
  }

  static userSettings() {
    return '/user-settings';
  }

  static callbackRoute() {
    return '/callback';
  }
}

export default GecoRouter;
