import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Container, Tooltip, Snackbar, Box,
} from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import StorageIcon from '@material-ui/icons/Storage';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import styles from '../../style';
import GecoBreadcrumbs from '../../util/GecoBreadcrumbs';
import ApiRouter from '../../util/ApiRouter';
import errorHandler from '../../util/requestError';
import { emptyArray, emptyObject, getDateFromTimestamp } from '../../util/util';
import GecoRouter from '../../util/GecoRouter';

const ProjectVersions = (props) => {
  const { classes, match, handleBreadcrumbs } = props;
  const { params } = match;
  const { getAccessTokenSilently } = useAuth0();

  const [versions, setVersions] = useState(emptyArray);
  const [selectedVersions, setSelectedVersions] = useState(emptyObject);
  const [snackOpen, setSnackOpen] = useState(false);

  const projectId = params.shortName;

  const getIcon = (version) => {
    if (version.id === selectedVersions.project_version_id) {
      return (
        <TimelineDot color="primary">
          <Tooltip title="Data version">
            <StorageIcon />
          </Tooltip>
        </TimelineDot>
      );
    }
    return (
      <TimelineDot color="primary" variant="outlined">
        <Tooltip title="Data version">
          <StorageIcon />
        </Tooltip>
      </TimelineDot>
    );
  };

  const fetchData = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const { data } = await ApiRouter.projectVersions(accessToken, projectId);
      if (data !== null) {
        setVersions(data.versions);
        setSelectedVersions(data.selected_versions);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const updateSelectedVersion = async (event) => {
    const data = { project_id: projectId, version_id: event.currentTarget.id };
    const accessToken = await getAccessTokenSilently();
    const { data: responseData } = await ApiRouter.setUserVersion(accessToken, data);
    setSelectedVersions(responseData);
  };

  useEffect(() => {
    handleBreadcrumbs(GecoBreadcrumbs.projectVersions(params.shortName));
    fetchData();
  }, [handleBreadcrumbs, params.shortName]);

  const onClick = (event) => {
    updateSelectedVersion(event);
  };

  const getPaperClass = (type) => ((type === 'inference_version') ? classes.versionCardInference : classes.versionCardProject);

  return (
    <div className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg">
        <Box flexDirection="column">
          <Typography variant="h4">
            Project versions for
            &nbsp;
            <Link to={GecoRouter.docsTab(projectId,
              selectedVersions.project_version_id,
              selectedVersions.time)}
            >
              {projectId}
            </Link>
          </Typography>

          <Box mt={10}>
            <Timeline align="left">
              {versions.map((version, index) => (
                <TimelineItem
                  id={version.id}
                  onClick={onClick}
                  key={version.id}
                  type={version.type}
                >
                  <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                      {getDateFromTimestamp(version.created_at, false)}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    {getIcon(version)}
                    {((index !== versions.length - 1)
                        && (versions[index].type === 'inference_version'))
                      && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={3} className={getPaperClass(version.type)}>
                      <Typography variant="h6" component="h1">
                        {version.description}
                      </Typography>
                      <Typography>
                        Id:
                        {' '}
                        {version.id}
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Box>
        </Box>

        <Snackbar open={snackOpen} autoHideDuration={5000} onClose={() => setSnackOpen(false)}>
          <Alert severity="success" onClose={() => setSnackOpen(false)}>
            Selected version
            {' '}
            {selectedVersions.project_version_id}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

ProjectVersions.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  handleBreadcrumbs: PropTypes.func.isRequired,
};

export default withStyles(styles)(ProjectVersions);
