class TabActivityStatus {
  constructor(features) {
    this.features = features;
    // eslint-disable-next-line no-console
    console.log(`TabActivityStatus constructor with features ${features}`);
  }

  static getTopLevelStatus(...rest) {
    return (rest.reduce((acc, cur) => acc + cur) > 0) ? 1 : 0;
  }

  getActivityStatus(key) {
    // eslint-disable-next-line no-console
    console.log(`getActivityStatus with features ${this.features} and key ${key}`);
    return this.features.includes(key) ? 1 : 0;
  }
}

export default TabActivityStatus;
