import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import styles from '../../style';

const CallbackRoute = (props) => {
  const { classes } = props;
  return (
    <div className={classes.content}>
      <div className={classes.appBarSpacer} />
      <div className={classes.appBarSpacer} />
    </div>
  );
};

CallbackRoute.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(CallbackRoute);
