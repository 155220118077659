import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Grid, Typography, withStyles,
} from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import styles from '../../style';
import ModelRunsTable from '../table/ModelRunsTable';
import ApiRouter from '../../util/ApiRouter';
import errorHandler from '../../util/requestError';
import { emptyObject } from '../../util/util';
import ModelRunsFilters from '../common/ModelRunsFilters';

const RunList = (props) => {
  const { classes, projectId, versionId } = props;
  const [modelRuns, setModelRuns] = useState(emptyObject);
  const [runId, setRunId] = useState('');
  const [description, setDescription] = useState('');
  const [seed, setSeed] = useState(0);
  const [modelType, setModelType] = useState('');
  const [version, setVersion] = useState('');
  const { getAccessTokenSilently } = useAuth0();

  const fetchData = async () => {
    try {
      const token = await getAccessTokenSilently();
      const { data } = await ApiRouter.getRunsList(token, projectId, versionId);
      setModelRuns(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const onFilterChange = (v) => {
    switch (v.name) {
      case 'runId':
        setRunId(v.value);
        break;
      case 'description':
        setDescription(v.value);
        break;
      case 'seed':
        setSeed(v.value);
        break;
      case 'type':
        setModelType(v.value);
        break;
      case 'version':
        setVersion(v.value);
        break;
      default:
        throw new Error('Component not supported');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Grid item xs={12} className={classes.tabDescription}>
          <Typography variant="caption">
            Model run explorer tool that shows all of the runs for this project version.
            Click on the version id above to go to version picker to switch to a different version.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ModelRunsFilters
            modelRuns={modelRuns}
            modelType={modelType}
            onFilterChange={onFilterChange}
            runId={runId}
            description={description}
            seed={seed}
            version={version}
          />
        </Grid>

        <Grid item xs={12}>
          <ModelRunsTable
            description={description}
            modelRuns={modelRuns}
            modelType={modelType}
            projectId={projectId}
            runId={runId}
            seed={seed}
            version={version}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

RunList.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  projectId: PropTypes.string.isRequired,
  versionId: PropTypes.string.isRequired,
};

export default withStyles(styles)(RunList);
