import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useHistory } from 'react-router-dom';

const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory();

  const domain = window.REACT_APP_AUTH0_DOMAIN || process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = window.REACT_APP_AUTH0_CLIENT_ID || process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = window.REACT_APP_AUTH0_CALLBACK_URL
    || process.env.REACT_APP_AUTH0_CALLBACK_URL;
  const audience = window.REACT_APP_AUTH0_AUDIENCE || process.env.REACT_APP_AUTH0_AUDIENCE;

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  if (!domain || !clientId || !redirectUri) {
    throw new Error(
      `Auth0ProviderWithHistory requires domain, clientId, and redirectUri. Got domain=${domain}, `
      + `clientId=${clientId}, redirectUri=${redirectUri}`,
    );
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience,
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
