import { getProtectedResource } from './ApiService';

const createApiMethod = (path, options = { method: 'GET' }) => (accessToken, ...args) => {
  const { data } = args;
  // eslint-disable-next-line no-console
  console.log(`Calling args ${args}`);
  const url = `api/${path(...args)}`;
  // eslint-disable-next-line no-console
  console.log(`Calling args ${args}`);
  // eslint-disable-next-line no-console
  console.log(`Calling ${url} with data ${data} and accessToken ${accessToken}`);
  return getProtectedResource(url, { data, ...options }, accessToken);
};

const createPostApiMethod = (path, options = { method: 'POST' }) => (accessToken, data, ...args) => {
  const url = `api/${path(...args)}`;
  // eslint-disable-next-line no-console
  console.log(`Calling ${url} with data ${data} and accessToken ${accessToken}`);
  return getProtectedResource(url, { data, ...options }, accessToken);
};

const ApiRouter = {
  login: createPostApiMethod(() => 'login'),

  logout: createPostApiMethod(() => 'logout'),

  loggedin: createApiMethod(() => 'loggedin'),

  getUserInfo: createApiMethod(() => 'user/info'),

  getRunsList: createApiMethod((projectId, projectVersionId) => `runs/list/${projectId}/${projectVersionId}`),

  getWebActivity: createApiMethod((type, showExternalOnly, page, rowsPerPage) => `crm/activity/${type}/${showExternalOnly}/${page}/${rowsPerPage}`),

  getActivityByDays: createApiMethod((days) => `crm/activity/days/${days}`),

  crmGetCompanies: createApiMethod(() => 'crm/companies'),

  crmAddCompany: createPostApiMethod(() => 'crm/company/add'),

  crmGetProjects: createApiMethod(() => 'crm/projects'),

  crmGetUsers: createApiMethod(() => 'crm/users'),

  crmAddUser: createApiMethod(() => 'crm/user/add'),

  crmDeactivateUser: createApiMethod(() => 'crm/user/deactivate'),

  crmSaveProjectConfigurations: createApiMethod(() => 'crm/projects/configuration/save'),

  crmAssignTag: createApiMethod(() => 'crm/tags/assign'),

  crmRemoveTag: createApiMethod(() => 'crm/tags/remove'),

  projectsList: createApiMethod(() => 'projects_list'),

  docsProjectVersion: createApiMethod((projectId, projectVersionId) => `docs/project_versions/${projectId}/${projectVersionId}`),

  docProjectVersion: createApiMethod((projectId, projectVersionId, fileName) => `docurl/project_version/${projectId}/${projectVersionId}/${fileName}`),

  pdfProjectVersion: createApiMethod((projectId, projectVersionId, fileName) => `docs/project_version/${projectId}/${projectVersionId}/${fileName}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    }),

  runDetails: createApiMethod((projectId, projectVersionId, runId, trialName) => `runs/details/${projectId}/${projectVersionId}/${runId}/${trialName}`),

  runDetailsInfo: createApiMethod((projectId, projectVersionId, runId) => `runs/details/info/${projectId}/${projectVersionId}/${runId}`),

  projectVersions: createApiMethod((projectId) => `project/versions/${projectId}/`),

  projectConfig: createApiMethod((projectId) => `project/configuration/${projectId}`),

  projectConfigurations: createApiMethod(() => 'project/configurations'),

  configuration: createApiMethod((shortName, trialName) => `configuration/${shortName}/${trialName}/`),

  setUserVersion: createPostApiMethod(() => 'user/version/'),
};

export default ApiRouter;
