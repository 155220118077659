import { useAuth0 } from '@auth0/auth0-react';
import {
  Container, Grid, Table, TableBody, TableCell, TableHead, TableRow,
  Typography, withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styles from '../../style';
import GecoBreadcrumbs from '../../util/GecoBreadcrumbs';
import { CodeSnippet } from './CodeSnippet';

const UserSettings = (props) => {
  const { classes, handleBreadcrumbs } = props;
  const { user } = useAuth0();

  if (!user) {
    return null;
  }

  useEffect(() => {
    handleBreadcrumbs(GecoBreadcrumbs.userSettings());
  }, [handleBreadcrumbs]);

  return (
    <div className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container>
        <Typography variant="h3">
          User Settings
        </Typography>
        <div className={classes.appBarSpacer} />
        {process.env.NODE_ENV === 'development' && (
          <div className>
            <CodeSnippet
              title="Decoded ID Token (only shown in debug)"
              code={JSON.stringify(user, null, 2)}
            />
          </div>
        )}
        <Grid
          container
          spacing={10}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"
              align="left"
            >
              User Info
            </Typography>
            <Table className={classes.userInfo}>
              <TableHead>
                <TableRow>
                  <TableCell>Attribute</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>email</TableCell>
                  <TableCell>{user.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Updated at</TableCell>
                  <TableCell>{user.updated_at}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Role</TableCell>
                  <TableCell>{(user.userRoles !== undefined) ? user.userRoles.join(', ') : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Company</TableCell>
                  <TableCell>{(user.company !== undefined) ? user.company : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Projects</TableCell>
                  <TableCell>{(user.projects !== undefined) ? user.projects.join(', ') : ''}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

UserSettings.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  handleBreadcrumbs: PropTypes.func.isRequired,
};

export default withStyles(styles)(UserSettings);
