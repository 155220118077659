import React, { useEffect, useState } from 'react';
// import ReactDOMServer from 'react-dom/server';
import { useParams } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
// import DOMPurify from 'dompurify';
import { useAuth0 } from '@auth0/auth0-react';
import ApiRouter from '../../util/ApiRouter';
import styles from '../../style';

const ProjectVersionDoc = () => {
  const { projectId, versionId, fileName } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [url, setURL] = useState({});
  // const [cleanHTML, setCleanHTML] = useState('');

  // eslint-disable-next-line
  console.log(`ProjectVersionDoc: projectId: ${projectId}, versionId: ${versionId}, fileName: ${fileName}`);

  useEffect(() => {
    const updateDocContent = async () => {
      const accessToken = await getAccessTokenSilently();
      const backendUrl = await ApiRouter.docProjectVersion(
        accessToken, projectId, versionId, fileName,
      );
      setURL(backendUrl.data);
    };
    updateDocContent();
  }, [projectId, versionId, fileName, getAccessTokenSilently]);

  // useEffect(() => {
  //   const cleanHTML = DOMPurify.sanitize(html);
  //   setCleanHTML(cleanHTML);
  // }, [html]);

  return (
    <iframe
      title={fileName}
      className="docport"
      width="100%"
      height="1000px"
      src={url}
    />
  );
};

export default withStyles(styles)(ProjectVersionDoc);
