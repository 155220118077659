import React, { useState } from 'react';
import './App.css';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { theme } from './theme';
import Dashboard from './component/common/Dashboard';

const App = () => {
  const [page] = useState(<Dashboard isAdmin={false} projects={{}} />);

  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">{page}</div>
    </MuiThemeProvider>
  );
};

export default withAuthenticationRequired(App, {
  onRedirecting: () => <div>Loading...</div>,
});
