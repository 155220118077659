import React, { useEffect, useState } from 'react';
import {
  withStyles, Container, Grid, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import styles from '../../style';
import errorHandler from '../../util/requestError';
import ProjectCard from '../card/ProjectCard';
import ApiRouter from '../../util/ApiRouter';
import GecoBreadcrumbs from '../../util/GecoBreadcrumbs';
import { emptyArray } from '../../util/util';

const ProjectsList = (props) => {
  const [projectsList, setProjectsList] = useState(emptyArray);
  const [settings, setSettings] = useState(emptyArray);
  const { getAccessTokenSilently } = useAuth0();
  const { handleBreadcrumbs, classes } = props;

  const getProjectsList = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const { data } = await ApiRouter.projectsList(accessToken);
      if (data !== null) {
        const { settings: newSettings, projects: newProjects } = data;
        setProjectsList(newProjects);
        setSettings(newSettings);
      }
      handleBreadcrumbs(GecoBreadcrumbs.projectsList());
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getProjectsList();
  }, [handleBreadcrumbs]);

  const columnWidths = [1, 7, 2];

  return (
    <div className={classes.componentHero}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg">
        <Grid container direction="row" spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.componentTitleDark}>
              Projects
            </Typography>
            <Typography variant="h6" className={classes.componentSubtitleDark}>
              Select a project to see analyses and results for those data.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="space-around"
        >
          <Grid item xs={columnWidths[0]}>
            <Typography variant="subtitle1" className={classes.diseaseAreaLabel}>
              Project id
            </Typography>
          </Grid>
          <Grid item xs={columnWidths[1]}>
            <Typography variant="subtitle1" className={classes.diseaseAreaLabel}>
              Description
            </Typography>
          </Grid>
          <Grid item xs={columnWidths[2]}>
            <Typography variant="subtitle1" className={classes.diseaseAreaLabel}>
              Active version (click to set)
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {projectsList != null && projectsList.length > 0 ? (
            projectsList.map((project) => (
              <ProjectCard
                key={project.id}
                id={project.id}
                description={project.description}
                versionId={
                  settings[project.id]?.project_version_id
                  || project.settings.project_version_id
                }
                columnWidths={columnWidths}
                time={settings[project.id]?.time || project.settings.time}
              />
            ))
          ) : (
            <Typography variant="body1">No projects found.</Typography>
          )}
        </Grid>
      </Container>
    </div>
  );
};

ProjectsList.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  handleBreadcrumbs: PropTypes.func.isRequired,
};

export default withStyles(styles)(ProjectsList);
