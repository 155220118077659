import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import ApiRouter from '../../util/ApiRouter';
import styles from '../../style';

const ProjectVersionDoc = () => {
  const { projectId, versionId, fileName } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [url, setURL] = useState(null);

  useEffect(() => {
    const updateDocContent = async () => {
      const accessToken = await getAccessTokenSilently();
      const backendUrl = await ApiRouter.docProjectVersion(
        accessToken, projectId, versionId, fileName,
      );
      setURL(backendUrl.data);
    };
    updateDocContent();
  }, [projectId, versionId, fileName, getAccessTokenSilently]);

  if (!url) {
    return <div>Loading...</div>; // Display a loading state while fetching the URL
  }

  if (url === 'INVALID') {
    // Display an error message and query again to fetch a new URL
    return (
      <div>
        Error: Invalid URL. Please refresh the page.
        <button onClick={updateDocContent}>Refresh</button>
      </div>
    );
  }

  return (
    <iframe
      id={fileName}
      className="docport"
      name={fileName}
      title={fileName}
      allow="fullscreen"
      sandbox="allow-same-origin allow-scripts"
      src={url}
    />
  );
};

export default withStyles(styles)(ProjectVersionDoc);
