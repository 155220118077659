import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#912FFF',
    },
    text: {
      secondary: '#B5B5B5',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"Open Sans", sans-serif',
    fontSize: 16,
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.375rem',
      letterSpacing: '-0.01em',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.33rem',
      letterSpacing: '0.01em',
    },
  },
  overrides: {
    MuiBreadcrumbs: {
      li: {
        fontSize: '1rem',
        letterSpacing: '-0.01em',
        color: 'textSecondary',
      },
    },
    MuiTableCell: {
      head: {
        fontSize: '1em',
        fontWeight: 600,
        color: '#050540',
        paddingLeft: '34px',
      },
      body: {
        fontSize: '1em',
        color: '#050540',
        paddingLeft: '34px',
      },
    },
    MuiTableRow: {
      root: {
        '&$hover:hover': {
          backgroundColor: '#f0eff0',
        },
      },
    },
  },
});

export default theme;
