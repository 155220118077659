import { React, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Breadcrumbs,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useAuth0 } from '@auth0/auth0-react';
import AppBar from '@material-ui/core/AppBar';
import classNames from 'classnames';
import styles from '../../style';
import { version } from '../../../package.json';
import GecoRouter from '../../util/GecoRouter';

const GecoLink = ({ color, to, children }) => (
  <Link color={color} component={RouterLink} to={to}>
    {children}
  </Link>
);

const GecoAppBar = ({ classes, breadCrumbs, isAdmin }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout } = useAuth0();
  const isProjectsList = breadCrumbs.length === 1 && breadCrumbs[0].label === 'Projects List';

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  const handleAdminClick = () => {
    // Handle navigation to admin page here
    handleMenuClose();
  };

  return (
    <AppBar
      position="absolute"
      color="default"
      elevation={0}
      className={classNames(classes.appBar)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          aria-controls="simple-menu"
          aria-haspopup="true"
          className={classes.menuButton}
          edge="start"
          onClick={handleMenuOpen}
        >
          <MenuIcon className={isProjectsList ? classes.menuIconDark : ''} />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <MenuItem disabled>
            <ListItemIcon><SettingsIcon /></ListItemIcon>
            <ListItemText>
              v
              {version}
            </ListItemText>
          </MenuItem>
          <RouterLink
            to={GecoRouter.userSettings()}
            className={classes.noUnderline}
          >
            <MenuItem>
              <ListItemIcon><SettingsApplicationsIcon /></ListItemIcon>
              <ListItemText>User settings</ListItemText>
            </MenuItem>
          </RouterLink>
          {isAdmin && (
            <MenuItem onClick={handleAdminClick}>
              <ListItemIcon>
                <SettingsApplicationsIcon />
              </ListItemIcon>
              <ListItemText primary="Admin" />
            </MenuItem>
          )}
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuItem>
        </Menu>
        {!isProjectsList && (
          <div>
            <Breadcrumbs aria-label="Breadcrumb">
              {breadCrumbs.map((breadCrumb, index) => {
                const last = index === breadCrumbs.length - 1;
                return (
                  <GecoLink
                    key={breadCrumb.label}
                    to={breadCrumb.link}
                    color="inherit"
                    className={classNames(last ? classes.breadCrumbFocus : '')}
                  >
                    {breadCrumb.label}
                  </GecoLink>
                );
              })}
            </Breadcrumbs>
          </div>
        )}
        <div className={classes.grow} />

        <GecoLink to="/">
          <img
            src={isProjectsList ? '/img/logo_dark.svg' : '/img/logo.svg'}
            className={classes.generableLogo}
            alt="Generable"
          />
        </GecoLink>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(GecoAppBar);
