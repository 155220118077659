const errorHandler = (error) => {
  if (error.response && error.response.status === 401) {
    window.location.reload();
  } else {
    // eslint-disable-next-line no-console
    console.log(`there was an error: ${error}`);
  }
};

/* eslint-env browser */

export default errorHandler;
