import { callExternalApi } from './callExternalApi';

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getProtectedResource = async (path, options = {}, accessToken) => {
  const url = `${apiServerUrl}/${path}`;
  const config = {
    ...options,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  // eslint-disable-next-line no-console
  console.log(`Calling ${url} with accessToken ${accessToken}`);
  return callExternalApi({ config, url }, accessToken);
};

export default getProtectedResource;
