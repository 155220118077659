import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Container, Grid,
} from '@material-ui/core';
import styles from '../../style';
import GecoBreadcrumbs from '../../util/GecoBreadcrumbs';
import { GecoTabs, GecoTab2 } from '../route/Tab';
import DocsActivityStatus from '../../util/DocsActivityStatus';
import TabPanel from '../route/TabPanel';
import DocList from '../tool/DocList';

const DocsTab = (props) => {
  const {
    classes, handleBreadcrumbs, match,
  } = props;
  const { params } = match;
  const { projectId, versionId } = params;

  const [tabSelevel2, setTabLevel2] = useState(0);

  const features = ['docs_list', 'runs_list'];
  const tabStatus = new DocsActivityStatus(features);

  useEffect(() => {
    handleBreadcrumbs(GecoBreadcrumbs.docsTab(projectId, versionId));
  }, [handleBreadcrumbs, projectId, versionId]);

  return (
    <div>
      <Container>
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={10}>
            <div>
              <GecoTabs
                className={classes.tabLevel2}
                value={tabSelevel2}
                onChange={(_, newValue) => {
                  setTabLevel2(newValue);
                }}
                aria-label="second level"
              >
                <GecoTab2 active={tabStatus.list} label="List" />
                {/* <GecoTab2 active={tabStatus.crossTabulation} label="Cross tabulation" />
                <GecoTab2 active={tabStatus.kaplanMeier} label="Kaplan-Meier" />
                <GecoTab2 active={tabStatus.biomarker} label="Biomarker" /> */}
              </GecoTabs>

              <TabPanel index={0} selectedTab={tabSelevel2}>
                <DocList
                  projectId={projectId}
                  versionId={versionId}
                  handleBreadcrumbs={handleBreadcrumbs}
                />
                {/* <TrialDetailsTableExport
                  jsonLink={jsonTrialDetailsTableLink}
                  csvLink={csvTrialDetailsTableLink}
                  csvNotAvailable={false}
                  trialArmsOverview={trialArmsOverview}
                  handleSelect={handleSelect}
                  handleExportClose={handleExportClose}
                /> */}
              </TabPanel>
              {/* <TabPanel index={1} selectedTab={tabSelevel2}>
                <CrossTabulation
                  shortName={shortName}
                  trialName={trialName}
                  selectedCohortId={selectedCohortId}
                />
              </TabPanel> */}
              {/* <TabPanel index={2} selectedTab={tabSelevel2}>
                <KaplanMeierExport
                  chartData={km}
                  jsonLink={jsonKaplanMeierLink}
                  csvLink={csvKaplanMeierLink}
                  csvNotAvailable={false}
                  handleSelect={handleSelect}
                  handleExportClose={handleExportClose}
                />
              </TabPanel> */}
              {/* <TabPanel index={3} selectedTab={tabSelevel2}>
                <BiomarkerChartExport
                  trialName={trialName}
                  chartData={sld}
                  jsonLink={jsonBiomarkerLink}
                  handleSelect={handleSelect}
                  handleExportClose={handleExportClose}
                />
              </TabPanel> */}
            </div>

          </Grid>
          {/* <Grid item xs={2}>
            <Grid item xs={12}>
              <div className={classes.sideComponent}>
                <TrialDetailsOverview trialsOverview={trialsOverview} />
              </div>
            </Grid>
          </Grid> */}
        </Grid>
      </Container>
    </div>
  );
};

DocsTab.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  handleBreadcrumbs: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(DocsTab);
